import React from 'react';
import { graphql } from 'gatsby';
import DefaultLayout from '../layouts';
import ShopProduct from '../components/ShopProduct/ShopProduct';
import { getComponent } from '../utils';
import styles from '../components/ShopProduct/styles.module.scss';

const ShopPage = ({ data }) => {
  const { products } = data.shopifyCollection || {};
  return (
    <DefaultLayout
      pageTitle={data.contentfulPage.title}
      metaData={data.contentfulPage.metaData || undefined}
    >
      <div className="base">
        <div className="container container--medium">
          {data.contentfulPage.content.map(context => {
            const Component = getComponent(
              context.internal.type.replace('Contentful', '')
            );

            return <Component key={context.id} {...context} />;
          })}
          {products && (
            <div className={styles.shop}>
              {products.map(product => (
                <ShopProduct key={product.shopifyId} {...product} />
              ))}
            </div>
          )}
        </div>
      </div>
    </DefaultLayout>
  );
};

export default ShopPage;

export const query = graphql`
  {
    contentfulPage(contentful_id: { eq: "3gWGBqHo2C3yNFcJvEZXTu" }) {
      id
      title
      metaData {
        ... on ContentfulPageMeta {
          title
          metaDescription
          image {
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
      }
      content {
        ... on ContentfulContentBlock {
          id
          title
          displayTitle
          textAlignment
          body {
            raw
            references {
              ... on ContentfulAsset {
                contentful_id
                __typename
                title
                file {
                  url
                }
              }
            }
          }
          callToAction {
            id
            buttonLink
            label
          }
          internal {
            type
          }
        }
      }
      callToAction {
        ... on ContentfulCtaButton {
          id
          label
          heading
        }
      }
    }
    shopifyCollection(title: { eq: "MaoBoxShop" }) {
      title
      products {
        title
        shopifyId
        descriptionHtml
        handle
        hasOutOfStockVariants
        variants {
          price
          availableForSale
        }
        media {
          ... on ShopifyMediaImage {
            id
            image {
              originalSrc
            }
          }
        }
      }
    }
  }
`;
