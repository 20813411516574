import React, { useContext } from 'react';
import { Link, graphql, navigate } from 'gatsby';
import cx from 'classnames';

import {
  CartContext,
  useAddItemToCart,
  quantities,
} from '../../contexts/CartContext';

import Spinner from '../../icons/Spinner';
import styles from './styles.module.scss';

const ShopProduct = ({
  title,
  descriptionHtml,
  shopifyId,
  hasOutOfStockVariants,
  variants,
  handle,
  images,
  media,
}) => {
  return (
    <div className={styles.tile}>
      <Link to={`/product/${handle}`} className={styles.image}>
        <img src={media[0].image.originalSrc} />
      </Link>
      {hasOutOfStockVariants && (
        <span className={cx('tag', styles.tag)}>Pre-order</span>
      )}
      <p className={styles.title}>
        <Link to={`/product/${handle}`}>{title}</Link>
      </p>
      <p className={styles.price}>
        $
        {Number.isInteger(variants[0].price)
          ? variants[0].price
          : variants[0].price.toFixed(2)}
      </p>
    </div>
  );
};

export default ShopProduct;
